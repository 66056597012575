<template>
  <b-row>
    <b-col cols="0" lg="4"></b-col>
    <b-col v-if="!reset" cols="12" lg="4">
      <h3>Log in as an existing user</h3>
      <b-form-group
          id="input-group-login-username"
          label="Username"
          label-for="input-login-username"
      >
        <b-form-input
            id="input-login-username"
            v-model="loginForm.username"
        ></b-form-input>
      </b-form-group>
      <div style="position: relative !important;">
        <b-form-group
          id="input-group-login-password"
          label="Password"
          label-for="input-login-password"
        >
          <b-form-input
              id="input-login-password"
              v-model="loginForm.password"
              v-on:keyup.enter="login"
              type="password"
          ></b-form-input>
          </b-form-group>
          <b-button block variant="primary" @click="login">Login</b-button>
          <a @click="reset=true" class="password-reset-link">Forgot password?</a>
        </div>
    </b-col>
    <b-col v-else cols="12" lg="4">
      <p>Please enter the email associated with your account</p>
      <b-form-group
          id="input-group-reset-password-email"
          label="Email Address"
          label-for="input-reset-password-email"
      >
        <b-form-input
            id="input-reset-password-email"
            v-model="reset_email"
            v-on:keyup.enter="resetPassword"
        ></b-form-input>
      </b-form-group>
      <b-button block variant="primary" @click="resetPassword">Submit</b-button>
      <b-button block @click="reset=false">Cancel</b-button>
    </b-col>
    <b-col v-if="allow_self_registration">
      <h3>Register as a new user</h3>
      <b-form-group
          id="input-group-register-username"
          label="Username"
          label-for="input-register-username"
      >
        <b-form-input
            id="input-register-username"
            v-model="registerForm.username"
        ></b-form-input>
      </b-form-group>
      <b-form-group
          id="input-group-register-fullname"
          label="Full Name"
          label-for="input-register-fullname"
      >
        <b-form-input
            id="input-register-fullname"
            v-model="registerForm.fullname"
        ></b-form-input>
      </b-form-group>
      <b-form-group
          id="input-group-register-email"
          label="Email"
          label-for="input-register-email"
          type="email"
      >
        <b-form-input
            id="input-register-email"
            v-model="registerForm.email"
        ></b-form-input>
      </b-form-group>
      <b-form-group
          id="input-group-register-password"
          label="Password"
          label-for="input-register-password"
      >
        <b-form-input
            id="input-register-password"
            v-model="registerForm.password"
            type="password"
        ></b-form-input>
      </b-form-group>
      <b-button type="submit" variant="primary" @click="register">Sign up</b-button>
    </b-col>
    <b-col cols="0" lg="4"></b-col>
  </b-row>
</template>

<script>
import http from "../http-common"

export default {
  name: "UserLogin",
  props: {
  },
  data() {
    return {
      registerForm: {
        username: '',
        fullname: '',
        email: '',
        password: '',
      },
      loginForm: {
        username: '',
        password: '',
      },
      alertShow: false,
      alertMessage: '',
      allow_self_registration: false,
      reset: false,
      reset_email: '',
    }
  },
  methods: {
    login() {
      const formData = new FormData();
      formData.append('username', this.loginForm.username);
      formData.append('password', this.loginForm.password);
      http.post('auth/token',
          formData,
      )
          .then((response) => {
            this.$emit('login-complete', response.data);
          })
          .catch(() => {
            this.$emit('show-alert',
                {
                  message: "Login failed. Please try again.",
                  type: "warning"
                })
          });
    },
    register() {
      http.post('user/signup',
          {
            'username': this.registerForm.username,
            'full_name': this.registerForm.fullname,
            'email': this.registerForm.email,
            'password': this.registerForm.password,
          })
      .then((response) => {
        this.alertMessage = "Successful registration";
        this.alertShow = true;
        this.$emit('login-complete', response.data);
      })
      .catch((err) => {
        this.alertMessage = err.message;
        this.alertShow = true;
      });
    },
    resetPassword() {
      http.post('user/init-reset-password', {}, {
        params: {email: this.reset_email}
      })
      .then(() => {
        this.$emit('show-alert', {
          message: "Check your email for a reset link.",
          type: "success",
        });
      })
      .catch((err) => {
        if(http.std_error_handler(this, err)) {
          this.$emit('show-alert', {message:err.response.data.detail});
        }
      })
      .finally(() => {
        this.reset = false;
        this.reset_email = '';
      });
    }
  },
  mounted() {
    http.get("user/signup")
    .then((resp) => {
      this.allow_self_registration = resp.data.allow_self_registration;
    })
    .catch((err) => {
      http.std_error_handler(this, err);
    });
  },
}
</script>

<style scoped>

.password-reset-link {
  float: right;
  right: 0 !important;
  top: 0 !important;
  position: absolute !important;
}

</style>